export const text = {
	h1: {
		fontFamily: "heading",
		fontSize: ["40px", null, null, null, "72px"],
		fontWeight: 400,
		letterSpacing: ["1.6px", null, null, null, "2.88px"],
		lineHeight: "120%",
		color: "dark",
	},
	h2: {
		fontFamily: "heading",
		fontSize: ["24px", null, null, null, "36px"],
		fontWeight: 600,
		letterSpacing: ["0.96px", null, null, null, "1.44px"],
		color: "iridium",
	},
	h3: {
		fontFamily: "heading",
		fontSize: "20px",
		fontWeight: 600,
		letterSpacing: "0.4px",
		color: "forestGreen",
	},
	preHeading: {
		fontFamily: "heading",
		fontSize: ["16px", null, null, null, "24px"],
		fontWeight: 700,
		letterSpacing: ["0.96px", null, null, null, "1.44px"],
		textTransform: "uppercase",
		color: "forestGreen",
	},
	p: {
		fontFamily: "body",
		fontSize: ["16px", null, null, null, "18px"],
		fontWeight: 400,
		color: "white",
		letterSpacing: ["0.64px", null, null, null, "0.72px"],
		lineHeight: "150%",
	},
	navLink: {
		fontFamily: "body",
		fontSize: "16px",
		fontWeight: 600,
		letterSpacing: "0.32px",
		textDecoration: "none",
		color: "forestGreen",
	},
	footerLink: {
		fontFamily: "body",
		fontSize: "18px",
		fontWeight: 600,
		letterSpacing: "0.36px",
		textDecoration: "none",
		color: "forestGreen",
	},
	disclaimerText: {
		fontFamily: "body",
		fontSize: "12px",
		letterSpacing: "0.24px",
		lineHeight: "125%",
		color: "darkGrey",
	},
};
